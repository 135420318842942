import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../lib/helpers";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import PortableText from "../../components/portableText";
import SEO from "../../components/seo";
import Container from "../../components/container";
import Elevator from "../../components/elevator";
import Layout from "../../components/layout";

import "../../styles/layout.scss";

export const query = graphql`
  query CoachQueryCycling {
    allSanityCoach {
      edges {
        node {
          _rawBio
          _rawBody
          _rawExtraBody
          _id
          name
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              url
              _id
            }
            alt
          }
          extraImage {
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            alt
          }
        }
      }
    }
  }
`;

const CyclingCoach = () => {
  let coaches = useStaticQuery(query);
  coaches = mapEdgesToNodes(coaches.allSanityCoach);

  const sykkelcoach = coaches[1] || [];

  return (
    <Layout>
      <SEO title="Sykkel Coach" />
      <div className="elevator__background"></div>

      <Container>
        <div className="coach">
          <Elevator
            title={sykkelcoach.name}
            image={sykkelcoach.image}
            info={sykkelcoach._rawBio}
            extraInfo={sykkelcoach._rawExtraBody}
          />
          {sykkelcoach._rawBody && (
            <div className="coach__meritter">
              <h3 className="coach__heading">
                Min idrettslige bakgrunn og mine meritter som syklist
              </h3>
              <div className="coach__row">
                <div className="coach__text">
                  {sykkelcoach && sykkelcoach._rawBody && (
                    <PortableText blocks={sykkelcoach._rawBody} />
                  )}
                </div>
                <img
                  className="coach__image"
                  src={imageUrlFor(buildImageObj(sykkelcoach.extraImage))
                    .width(600)
                    .height(600)
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt={sykkelcoach.extraImage.alt}
                />
              </div>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default CyclingCoach;
